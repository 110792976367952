import { CheckIcon } from "@heroicons/react/20/solid";
import { formatTimeFromTimestampScalar } from "@src/common/lib/DateUtils";
import { Tooltip } from "flowbite-react";
import React from "react";

type Props = { completedAt };

function ArrivedTimeDisplay({ completedAt }: Props) {
  return (
    <div className="flex justify-end">
      <Tooltip content="Completed">
        <span className="text-green-400 font-semibold flex truncate">
          <CheckIcon className="h-4 w-4 mr-1" />
          {formatTimeFromTimestampScalar(completedAt)}
        </span>
      </Tooltip>
    </div>
  );
}

export default ArrivedTimeDisplay;
