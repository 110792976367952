import {
  RoutingNodeEta,
  StopEta,
} from "@api/graphql/generated/generated-types";
import classNames from "classnames";
import React from "react";
import { Tooltip } from "@src/common/components";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { formatTimeFromTimestampScalar } from "@src/common/lib/DateUtils";
import moment from "moment";

type Props = {
  stopEta?: StopEta | undefined;
  routingNodeEta?: RoutingNodeEta | undefined;
};

const LIKELY_LATE_DANGER_ZONE_THRESHOLD_IN_MINUTES = 30;
function EtaDisplay({ stopEta, routingNodeEta }: Props) {
  const eta = stopEta || routingNodeEta;

  const isRoutingNodeEta = (
    eta: StopEta | RoutingNodeEta
  ): eta is RoutingNodeEta => {
    return (eta as RoutingNodeEta).isLikelyEarly !== undefined;
  };

  return (
    <div
      className={classNames({
        "flex gap-1 text-right justify-end col-span-1 truncate min-w-fit": true,
        "text-yellow-400 font-bold":
          eta?.isLikelyLate &&
          (eta?.likelyLateByInMinutes === null ||
            eta?.likelyLateByInMinutes === undefined ||
            eta?.likelyLateByInMinutes <
              LIKELY_LATE_DANGER_ZONE_THRESHOLD_IN_MINUTES),
        "text-red-600 font-bold":
          eta?.isLikelyLate &&
          eta?.likelyLateByInMinutes &&
          eta?.likelyLateByInMinutes >=
            LIKELY_LATE_DANGER_ZONE_THRESHOLD_IN_MINUTES,
        "text-purple-600 font-bold":
          eta &&
          isRoutingNodeEta(eta) &&
          eta?.isLikelyEarly &&
          eta?.likelyEarlyByInMinutes &&
          eta?.likelyEarlyByInMinutes > 0,
      })}
    >
      {eta?.isLikelyLate && (
        <div className="flex flex-init">
          <Tooltip
            content={
              eta?.likelyLateByInMinutes &&
              eta?.likelyLateByInMinutes >=
                LIKELY_LATE_DANGER_ZONE_THRESHOLD_IN_MINUTES
                ? `Likely more than ${LIKELY_LATE_DANGER_ZONE_THRESHOLD_IN_MINUTES} minutes late based on our estimate. This may change.`
                : "Likely late based on our estimate. This may change."
            }
          >
            <ExclamationTriangleIcon className="h-4 w-4" />
          </Tooltip>
        </div>
      )}
      {eta && isRoutingNodeEta(eta) && eta.isLikelyEarly && (
        <div className="flex flex-init">
          <Tooltip
            content={`Likely early by ${moment
              .duration(eta.likelyEarlyByInMinutes, "minutes")
              .humanize()}`}
          >
            <ExclamationTriangleIcon className="h-4 w-4" />
          </Tooltip>
        </div>
      )}
      ETA {eta ? formatTimeFromTimestampScalar(eta.eta) : "--:-- --"}
    </div>
  );
}

export default EtaDisplay;
