import {
  Order,
  Shipment,
  ShipmentStopType,
  Stop,
  Task,
  TimeWindow,
} from "@api/graphql/generated/generated-types";
import { Badge } from "@src/common/components";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";
import { FC, ReactNode } from "react";
import {
  BadgeMeta,
  makeStopDisplayStatusBadge,
} from "../../shipments/utils/stopBadgeFormatters";
import { ShipmentRow } from "@src/shipments/types";
import {
  ArrowDownTrayIcon,
  ArrowUpIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import DisplayServiceType from "@src/servicetypes/components/DisplayServiceType";

export type StopWithOrderId = Stop & {
  orderId: string;
};

export interface SortableItem {
  id: string;
  meta: {
    shipmentId: string;
    stopId: string;
    stopType: ShipmentStopType;
  };
  content1: ReactNode;
  content2: ReactNode;
  content3: ReactNode;
  className?: string;
  stop: Stop | null | undefined;
}

export function formatDriverTasksToSortableList(
  tasks: Task[],
  highlightedShipmentIds: string[],
  selectedShipment?: Shipment | null | undefined
): SortableItem[] {
  if (tasks.length === 0) {
    return [];
  }
  const formattedTasks = tasks.map((task: Task) => {
    const stop: Stop = task.stop;
    const shipment: Shipment = stop.shipment;
    const orderId = shipment?.order?.id;
    const stopType: ShipmentStopType = stop.type;
    const stopTimeWindow: TimeWindow = stop.timeWindow;
    const stopBadgeMeta = makeStopDisplayStatusBadge(
      stop.timeStatus || undefined,
      stopTimeWindow,
      stop.type === ShipmentStopType.PickUp
    );
    let stopTypeBadgeColor: string = "gray";
    if (stopType === ShipmentStopType.DropOff) {
      stopTypeBadgeColor = "purple";
    }
    if (stopType === ShipmentStopType.Return) {
      stopTypeBadgeColor = "teal";
    }
    const serviceTypeName = stop?.shipment?.order?.serviceType?.name;
    const serviceTypeColor = stop?.shipment?.order?.serviceType?.color;
    const StopTypeIcon = chooseIconForStopType(stopType);
    return {
      id: task.stop.id,
      stop: stop,
      meta: {
        stopId: task.stop.id,
        shipmentId: stop.shipmentId,
        stopType: stopType,
      },
      content1: (
        <div className="flex items-center ">
          <div className="flex-init truncate text-base text-slate-900">
            {stop.dispatchZone}
          </div>
          <Badge
            size={"xs"}
            className="ml-2 text-xs mr-1"
            color={stopTypeBadgeColor}
          >
            <div className="flex gap-1">
              <StopTypeIcon className="h-4 w-4" />
              <span className=" font-normal">
                {formatTitleCaseWithUnderscores(stopType)}
              </span>
            </div>
          </Badge>
          {serviceTypeName && (
            <DisplayServiceType
              serviceType={serviceTypeName}
              color={serviceTypeColor || undefined}
            />
          )}
        </div>
      ),
      content2: (
        <div className="truncate text-xs text-slate-600">
          {stop.streetAddress}
        </div>
      ),
      content3: <StopTimeStatusOnSortable badgeMeta={stopBadgeMeta} />,
      className: highlightedShipmentIds.includes(task.stop.shipmentId)
        ? "bg-sky-100 shadow-md"
        : "bg-white shadow-md",
    };
  });
  return formattedTasks;
}

export function formatOrderStopsToSortableList(
  stops: Stop[] | StopWithOrderId[],
  selectedRow: ShipmentRow | null,
  highlightedShipmentIds: string[]
): SortableItem[] {
  const sortableList = stops.map((stop: Stop | StopWithOrderId) => {
    const shipment: Shipment = stop.shipment;
    const order: Order = shipment?.order;
    const orderId = order?.id || ("orderId" in stop ? stop.orderId : undefined);
    const stopType: ShipmentStopType = stop.type;
    const badgeMeta = makeStopDisplayStatusBadge(
      stop.timeStatus || undefined,
      stop.timeWindow,
      stop.type === ShipmentStopType.PickUp
    );
    let stopTypeBadgeColor: string = "gray";
    if (stopType === ShipmentStopType.DropOff) {
      stopTypeBadgeColor = "purple";
    }
    if (stopType === ShipmentStopType.Return) {
      stopTypeBadgeColor = "teal";
    }
    const serviceTypeName = selectedRow?.shipment?.order?.serviceType?.name;
    const serviceTypeColor = selectedRow?.shipment?.order?.serviceType?.color;
    const StopTypeIcon = chooseIconForStopType(stopType);
    return {
      id: stop.id,
      stop: stop,
      meta: {
        stopId: stop.id,
        shipmentId: stop.shipmentId,
        stopType: stopType,
      },
      content1: (
        <div className="flex items-center ">
          <div className="flex-init truncate text-base text-slate-900">
            {stop.dispatchZone}
          </div>
          <Badge className="ml-2 text-xs mr-1" color={stopTypeBadgeColor}>
            <div className="flex gap-1">
              <StopTypeIcon className="h-4 w-4" />
              <span className=" font-normal">
                {formatTitleCaseWithUnderscores(stopType)}
              </span>
            </div>
          </Badge>
          {serviceTypeName && (
            <DisplayServiceType
              serviceType={serviceTypeName}
              color={serviceTypeColor || undefined}
            />
          )}
        </div>
      ),
      content2: (
        <div className="truncate text-xs text-slate-600">
          {stop.streetAddress}
        </div>
      ),
      content3: <StopTimeStatusOnSortable badgeMeta={badgeMeta} />,
      className: highlightedShipmentIds.includes(stop.shipmentId)
        ? "bg-sky-100 shadow-md"
        : "bg-white shadow-md",
    };
  });
  return sortableList;
}

type StopTimeStatusOnSortableProps = {
  badgeMeta: BadgeMeta;
};

function StopTimeStatusOnSortable({
  badgeMeta,
}: StopTimeStatusOnSortableProps) {
  return (
    <div>
      {badgeMeta.shouldShowBadge ? (
        <Badge color={badgeMeta.badgeColor} size="xs">
          {badgeMeta.badgeText}
        </Badge>
      ) : (
        <div className="text-slate-600 text-xs font-semibold">
          {badgeMeta.badgeText}
        </div>
      )}
    </div>
  );
}

export function chooseIconForStopType(
  type: ShipmentStopType
): FC<React.SVGProps<SVGSVGElement>> {
  switch (type) {
    case ShipmentStopType.PickUp:
      return ArrowUpIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStopType.DropOff:
      return ArrowDownTrayIcon as FC<React.SVGProps<SVGSVGElement>>;
    case ShipmentStopType.Return:
      return ArrowUturnLeftIcon as FC<React.SVGProps<SVGSVGElement>>;
    default:
      return ArrowUpIcon as FC<React.SVGProps<SVGSVGElement>>;
  }
}
