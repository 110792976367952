import React from "react";
import { Badge } from "@src/common/components";

type Props = {
  serviceType: string;
  color: string | undefined;
  className?: string;
};

function DisplayServiceType({ serviceType, color, className = "" }: Props) {
  if (color) {
    return (
      <div className={`flex ${className}`}>
        <Badge color={color}>{serviceType}</Badge>
      </div>
    );
  }

  return <p className=" text-slate-400">{serviceType}</p>;
}

export default DisplayServiceType;
