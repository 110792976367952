import React from "react";
import { Badge } from "flowbite-react";
import DisplayServiceType from "@src/servicetypes/components/DisplayServiceType";
import {
  RoutingNodeEta,
  ShipmentStopType,
  Stop,
  StopEta,
  StopTemplate,
  Task,
  TaskStatus,
} from "@api/graphql/generated/generated-types";
import { getTimeWindowText } from "../../../shipments/utils/shipmentRowFormatter";
import { displayTimeWindowOfDay } from "@src/shipmentTemplates/utils/makeShipmentTemplatesRows";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";
import { chooseIconForStopType } from "../../../shipments/utils/SortableListFormatters";
import { hashCode, intToRGB } from "@src/common/lib/colorUtils";
import EtaDisplay from "./components/EtaDisplay";
import ArrivedTimeDisplay from "./components/ArrivedTimeDisplay";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@src/common/components";
import moment from "moment";
import {
  getSubtitle,
  renderShipmentStatus,
} from "@src/common/lib/shipmentStatus";

const orderIdToColor = (orderId) => `#${intToRGB(hashCode(orderId))}`;

export const StopTypeBadge = ({ stopType, color, Icon }) => (
  <Badge className="text-xs" color={color}>
    <div className="flex gap-1">
      {Icon && <Icon className="h-4 w-4" />}
      {stopType && (
        <span className="truncate">
          {formatTitleCaseWithUnderscores(stopType)}
        </span>
      )}
    </div>
  </Badge>
);

type Props = {
  task: Task | undefined;
  index: number;
  stopTemplate?: StopTemplate | undefined;
  showIndex?: boolean;
  stop?: Stop | undefined;
  stopEta?: StopEta | undefined;
  routingNodeEta?: RoutingNodeEta | undefined;
  selectedStopDate?: string | undefined;
};

function DriverTask({
  task,
  index,
  stopTemplate,
  showIndex = false,
  stopEta = undefined,
  stop: stopOverride,
  routingNodeEta,
  selectedStopDate,
}: Props) {
  let stop: Stop | undefined = undefined;
  if (task) {
    stop = task.stop;
  }
  if (stopOverride) {
    stop = stopOverride;
  }
  const stopType = stop?.type || stopTemplate?.type;
  const StopTypeIcon = stopType ? chooseIconForStopType(stopType) : null;
  const stopTypeBadgeColor = stopType
    ? {
        [ShipmentStopType.DropOff]: "purple",
        [ShipmentStopType.Return]: "teal",
      }[stopType] || "gray"
    : "gray";
  const backgroundColor = orderIdToColor(
    stop?.shipment.shipmentTemplate?.id ||
      stop?.shipmentId ||
      stopTemplate?.shipmentTemplateId ||
      ""
  );
  const serviceType =
    stop?.shipment?.order?.serviceType ||
    stopTemplate?.shipmentTemplate?.order.serviceType;
  const title =
    stop?.dispatchZone ||
    stopTemplate?.streetAddress ||
    stopTemplate?.city ||
    "";
  const shipment = stop?.shipment || task?.stop?.shipment;
  const isCompleted = task?.status === TaskStatus.Completed;
  const completedAt = task?.stop.completedAt;
  const hasStartedTask = task?.status === TaskStatus.Started;
  const etaDate = task && moment(task.etaEpochSeconds * 1000);
  const isEtaInFuture = moment().isBefore(etaDate);
  const etaDisplay =
    isEtaInFuture && etaDate ? `ETA: ${etaDate.format("h:mm a")}` : "";
  const serviceTypeName = serviceType?.name;
  const serviceTypeColor = serviceType?.color;

  const dueTime = stop?.timeWindow?.close;
  const dueTimeFormatted = dueTime
    ? moment.unix(parseInt(dueTime)).format("YYYY-MM-DD")
    : null;
  const showDueDate = dueTimeFormatted && dueTimeFormatted !== selectedStopDate;

  return (
    <div className="flex text-xs p-1 rounded-md" key={index}>
      <div
        className="border rounded-full flex items-center justify-center text-white my-auto"
        style={{ width: 30, height: 30, backgroundColor, minWidth: 30 }}
      >
        {showIndex ? <span>{index + 1}</span> : "?"}
      </div>
      <div className="ml-2 flex-1 flex flex-col gap-0.5">
        <div className="flex-1 grid grid-cols-3 items-center justify-between">
          <div className="flex col-span-2 gap-2 min-w-0">
            <span className="font-semibold truncate text-xs text-slate-900 my-auto flex gap-1">
              {hasStartedTask && !completedAt && (
                <Tooltip content="Driver is on the way to this stop.">
                  <ArrowRightCircleIcon className="h-4 w-4 text-green-400" />
                </Tooltip>
              )}
              {title}
            </span>
          </div>
          <div className="flex min-w-0 ml-2 justify-end">
            <span className="font-normal truncate text-slate-700 min-w-fit">
              {stop
                ? getTimeWindowText(stop.timeWindow, true, !!showDueDate)
                : displayTimeWindowOfDay(stopTemplate?.timeWindow)}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-3 justify-between">
          <span className="truncate text-xs text-slate-600 col-span-2">
            {getSubtitle({
              dispatchZone:
                stop?.dispatchZone || stopTemplate?.dispatchZone || "",
              streetAddress:
                stop?.streetAddress || stopTemplate?.streetAddress || "",
              companyName: stop?.companyName || stopTemplate?.companyName || "",
              city: stop?.city || stopTemplate?.city || "",
            })}
          </span>
          {!isCompleted && (stopEta || routingNodeEta) && (
            <div className="col-span-1 flex items-center justify-end">
              <EtaDisplay stopEta={stopEta} routingNodeEta={routingNodeEta} />
            </div>
          )}
          {!isCompleted && !stopEta && (
            <div className="truncate min-w-fit">{etaDisplay}</div>
          )}
          {isCompleted && <ArrivedTimeDisplay completedAt={completedAt} />}
        </div>
        <div className="grid grid-cols-3 gap-1 justify-between">
          <div className="flex col-span-2 truncate gap-1">
            {stopType && (
              <StopTypeBadge
                stopType={stopType}
                color={stopTypeBadgeColor}
                Icon={StopTypeIcon}
              />
            )}
            {serviceTypeName && (
              <DisplayServiceType
                serviceType={serviceTypeName}
                color={serviceTypeColor || undefined}
              />
            )}
          </div>
          <div className="flex col-span-1 gap-1  justify-end truncate min-w-fit">
            {shipment && renderShipmentStatus(shipment)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverTask;
