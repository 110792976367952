import {
  Shipment,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";

interface SubtitleProps {
  dispatchZone: string;
  streetAddress: string;
  companyName?: string;
  city: string;
}

export const renderShipmentStatus = (shipment: Shipment) => {
  if (!shipment) {
    return;
  }
  const cn = "text-xs font-bold p-1 text-slate-600";
  if (shipment.status === ShipmentStatus.Created) {
    return <span className={cn}>NEW</span>;
  }
  if (shipment.status === ShipmentStatus.InTransit) {
    return <span className={cn}>IN TRANSIT</span>;
  }
};

export const getSubtitle = ({
  dispatchZone,
  streetAddress,
  companyName,
  city,
}: SubtitleProps): string => {
  if (dispatchZone && dispatchZone !== streetAddress) {
    return streetAddress;
  } else if (companyName) {
    return companyName;
  } else {
    return city;
  }
};
